import * as React from 'react';
import tw, { styled } from 'twin.macro';
import Page from '../components/Common/Page';
import IndexLayout from '../layouts';

const Title = styled.p(() => [
  tw`flex w-full text-2xl md:text-[32px] justify-center font-bold text-spaceGray pt-12 mb-16`
]);

const RecruitmentPolicyPage: React.FC = () => (
  <IndexLayout transparent>
    <Page className="mx-auto lg:max-w-[1110px] pt-8 pb-32">
      <Title>Recruitment Privacy Policy</Title>
      <p>For Orange Cap Innovative Company Limited (the "Company") could consider your job application information, please carefully read this Privacy Policy before you, as an applicant, (the "Job Applicant") provide the Company with your application information. The Company warrants that it will store the Job Applicant's personal data in accordance with information security standards and applicable laws.</p>
      <p className="font-semibold underline">Personal Data being Processed</p>
      <p>During the recruitment process, the Company may obtain the Job Applicant's personal data (1) directly from the Job Applicant as provided in the application form, resume, conversation or interview, (2) from recruitment agencies or any referrals that the Job Applicant may have given consent to them to disclose and transfer the Job Applicant's information to the Company for the purpose of applying for a job position, and (3) from any third party that the Job Applicant have indicated as a reference person that the Company can contact them for further information.</p>
      <p>During the process of filing out the application form and job interview, the Company is necessary to collect, compile, and use the Job Applicant's personal data which are name, surname, identification card number, contact information, educational background, work experience, interest or skill as well as any other verification documents. For some positions, it may include the information regarding health and disability (if relevant and with the Job Applicant's consent).</p>
      <p>Once the Job Applicant has passed the selection process by the Company's interview, the Company may have to additionally collect the sensitive personal data which are criminal record and health check information. In this regard, the Company will request for a consent from the Job Applicant in accordance with applicable laws. Nevertheless, the Company has to inform the Job Applicant that, for some positions, all personal data deemed necessary for the Company to assess the qualification and suitability of the Job Applicant.</p>
      <p className="font-semibold underline">Purpose of the Personal Data Processing</p>
      <p>The Company is necessary to collect, compile and use the Job Applicant's personal data for the following purposes: (1) to verify the identity including the accuracy of the personal data and other information that the Job Applicant has provided to the Company, (2) to assess the suitability for the position that the Job Applicant has applied, and (3) to contact the Job Applicant during the recruitment process, including (4) to contact the Job Applicant in the future in case that the Company may other suitable position. Unless the Job Applicant has expressly and explicitly requested the Company to delete or destroy the personal data immediately, the Company reserves the rights to store all Job Applicant's personal data for a period of 1 calendar year even if the Job Applicant has not been selected as an employee of the Company.</p>
      <p className="font-semibold underline">Disclosure of the Personal Data</p>
      <p>Generally, the Job Applicant's personal data will not be disclosed, except in the circumstance that the Company would need to disclose and/or transfer such Job Applicant's personal data for the benefit of the recruitment process to the following person: (1) a service provider providing any related recruitment services for the Company, (2) any Company's affiliates that may have suitable positions for the Job Applicant, (3) any reference person that the Company is necessary to share the Job Applicant's personal data in order to check the work experience, and (4) any other third party to whom the Job Applicant has given consent to disclose the personal data to such person.</p>
      <p className="font-semibold underline">Data Subject Rights</p>
      <p>The Company respects the Job Applicant's rights as the data subject under applicable laws. The Job Applicant can contact the Company to exercise the rights which are the right to withdraw consent, the right to access and request a copy of the personal data, the right to correct the personal data, the right to request for data portability in the event that the Company stores the personal data in the format which is readable or commonly used by automated tools or equipment including the right to transfer transfer such personal data to other data controllers, the right to object to any processing of the personal data, the right to request for the deletion or de-identification of the personal data upon there is no necessity to process or upon the withdrawal of the consent, the right to request for suspension of the use of the personal data, or the right to file a complaint in the case of a violation of the personal data. You can contact the Company at thip@orangecapinnovative.com.</p>         
    </Page>
  </IndexLayout>
);

export default RecruitmentPolicyPage;
